import { LogoSVGImport } from "../index"

const logo: LogoSVGImport = {
    id: "logo-4",
    svg: `
	<svg width="135" height="135" viewBox="0 0 135 135" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M85.9282 46.8209C90.6669 51.5596 98.4331 51.5596 103.172 46.8209L122.127 27.8661C109.859 10.9911 89.9693 0 67.5 0C59.1941 0 51.2436 1.51375 43.8855 4.25166C44.3857 5.08093 44.978 5.87071 45.6888 6.58151L85.9282 46.8209Z" />
<path d="M49.5324 87.9027C44.7938 83.164 37.0276 83.164 32.2889 87.9027L12.9524 107.239C25.2335 124.061 45.0833 135 67.5 135C76.0033 135 84.1249 133.407 91.6146 130.551C91.1013 129.695 90.4958 128.879 89.7718 128.142L49.5324 87.9027Z" />
<path d="M135 67.4998C135 59.1018 133.447 51.0592 130.643 43.6353C129.88 44.1091 129.169 44.662 128.511 45.3201L88.2713 85.5595C83.5326 90.2982 83.5326 98.0644 88.2713 102.803L107.397 121.929C124.127 109.648 135 89.8506 135 67.4998Z" />
<path d="M47.1894 49.1638C51.9281 44.4251 51.9281 36.6589 47.1894 31.9202L28.0241 12.7549C11.0569 25.0228 0 44.9648 0 67.4999C0 75.8979 1.55324 83.9405 4.35696 91.3645C5.29154 90.838 6.17346 90.193 6.96324 89.4032L47.1894 49.1638Z" />
</g>
</svg>

`,
}

export default logo
