import { LogoSVGImport } from "../index"

const logo: LogoSVGImport = {
    id: "logo-9",
    svg: `
	<svg width="152" height="137" viewBox="0 0 152 137" xmlns="http://www.w3.org/2000/svg">
    <path d="M122.484 134H125.113C142.702 134 153.698 114.989 144.904 99.7873L119.347 55.5958L93.7911 11.4043C84.9965 -3.79689 63.0167 -3.79689 54.2221 11.4043L52.9009 13.6825C48.8172 20.7436 48.8172 29.43 52.9009 36.491L79.8719 83.1206L102.706 122.609C106.776 129.657 114.316 134 122.484 134Z" />
    <path d="M6.51242 93.8984L3.09601 99.7871C-5.69858 114.988 5.29799 134 22.8872 134H29.7066C47.2958 134 58.2924 114.988 49.4978 99.7871L46.0814 93.8851C37.2868 78.6839 15.2937 78.6839 6.51242 93.8984Z" />
    </svg>
    
`,
}

export default logo
