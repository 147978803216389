import { LogoSVGImport } from "../index"

const logo: LogoSVGImport = {
    id: "logo-7",
    svg: `
	<svg width="134" height="134" viewBox="0 0 134 134" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M102.45 94.7317C101.99 89.7035 98.2378 86.2706 93.0942 86.1685L87.8358 86.0664C87.823 86.0664 87.8103 86.0664 87.7975 86.0664H87.7848C87.772 86.0664 87.772 86.0664 87.7592 86.0664C87.7082 86.0664 87.6699 86.0792 87.6188 86.0792C87.5423 86.0919 87.4657 86.0919 87.3891 86.1047C87.3381 86.1175 87.287 86.1302 87.2359 86.1557C87.1721 86.1813 87.0956 86.194 87.0317 86.2195C86.9807 86.2451 86.9296 86.2706 86.8786 86.2961C86.8148 86.3216 86.7637 86.3599 86.7127 86.3982C86.6616 86.4365 86.6233 86.4748 86.5723 86.5131C86.5212 86.5514 86.4702 86.5896 86.4319 86.6407C86.3936 86.679 86.3553 86.73 86.317 86.7683C86.2787 86.8194 86.2277 86.8704 86.2021 86.9215C86.1638 86.9725 86.1383 87.0236 86.1128 87.0746C86.0873 87.1256 86.049 87.1895 86.0235 87.2405C85.9979 87.2915 85.9852 87.3554 85.9724 87.4192C85.9596 87.483 85.9341 87.534 85.9213 87.5978C85.9086 87.6616 85.9086 87.7255 85.8958 87.7893C85.8958 87.8403 85.8831 87.8914 85.8831 87.9552C85.8831 87.9679 85.8831 87.9807 85.8831 87.9934C85.8831 88.0062 85.8831 88.019 85.8831 88.0317L85.9852 93.2896C86.0745 98.4199 89.5205 102.185 94.5492 102.644L100.956 103.231C101.02 103.231 101.071 103.244 101.122 103.244C101.173 103.244 101.237 103.244 101.288 103.231C101.454 103.218 101.62 103.167 101.773 103.116C101.799 103.104 101.837 103.091 101.862 103.078C102.054 103.001 102.22 102.887 102.373 102.759C102.399 102.746 102.411 102.721 102.437 102.695C102.59 102.555 102.718 102.389 102.807 102.197C102.82 102.172 102.82 102.159 102.832 102.134C102.922 101.929 102.998 101.725 103.011 101.496V101.483C103.011 101.419 103.011 101.368 103.011 101.304C103.011 101.24 103.024 101.189 103.011 101.125L102.45 94.7317ZM89.8141 93.2131L89.7503 89.9333L93.0304 89.9971C96.2595 90.0609 98.3526 91.9496 98.6334 95.0763L99.0035 99.1984L94.8811 98.8283C91.8179 98.5348 89.8652 96.3908 89.8141 93.2131Z"  />
<path d="M133.783 69.1183C133.821 69.0545 133.847 68.978 133.872 68.9141C133.885 68.8631 133.911 68.8248 133.923 68.7738C133.936 68.71 133.949 68.6461 133.962 68.5823C133.974 68.5185 133.987 68.4675 134 68.4037C134 68.3399 134 68.2761 134 68.225C134 68.174 134 68.0974 134 68.0463C134 67.9825 133.974 67.9315 133.962 67.8677C133.949 67.8039 133.949 67.7401 133.923 67.6762C133.911 67.6252 133.885 67.5869 133.872 67.5359C133.847 67.4593 133.821 67.3955 133.783 67.3317C133.77 67.2934 133.732 67.2679 133.719 67.2296C133.668 67.153 133.63 67.0764 133.579 67.0126C133.579 67.0126 133.579 66.9999 133.566 66.9999C133.528 66.9616 133.489 66.9233 133.451 66.885C133.413 66.834 133.362 66.7957 133.324 66.7446L123.777 58.794C116.91 53.0766 108.244 53.4722 101.697 59.7894L94.3832 66.8467C94.3705 66.8595 94.3705 66.8722 94.3577 66.8722C94.345 66.885 94.3322 66.885 94.3322 66.8978C94.2429 66.9871 94.2046 67.0892 94.1408 67.1913C94.0769 67.2934 93.9876 67.3955 93.9365 67.5103C93.8855 67.6252 93.8855 67.7528 93.86 67.8677C93.8344 67.9825 93.7961 68.0974 93.7961 68.225C93.7961 68.3526 93.8344 68.4675 93.86 68.5823C93.8855 68.71 93.8855 68.8248 93.9365 68.9397C93.9876 69.0545 94.0642 69.1566 94.1408 69.2587C94.2046 69.3608 94.2429 69.4629 94.3322 69.5522C94.345 69.565 94.3577 69.565 94.3577 69.5778C94.3705 69.5905 94.3705 69.6033 94.3832 69.6033L101.697 76.6606C105.155 80.0042 109.214 81.6888 113.285 81.6888C116.91 81.6888 120.548 80.3488 123.777 77.656L133.324 69.7054C133.387 69.6543 133.438 69.5905 133.502 69.5267C133.528 69.5012 133.553 69.4884 133.566 69.4629C133.566 69.4629 133.566 69.4501 133.579 69.4501C133.63 69.3863 133.681 69.3098 133.719 69.2332C133.732 69.1821 133.77 69.1566 133.783 69.1183ZM121.313 74.7081C115.978 79.1492 109.469 78.8429 104.338 73.9041L98.4547 68.225L104.338 62.546C109.469 57.5943 115.966 57.2881 121.313 61.742L129.099 68.225L121.313 74.7081Z"  />
<path d="M83.9047 84.522L90.3883 76.7372L96.872 84.522C97.2549 84.9814 97.7909 85.2111 98.3397 85.2111C98.7737 85.2111 99.2076 85.0708 99.565 84.7645C100.382 84.0881 100.484 82.8757 99.8075 82.0717L91.8944 72.5513C91.8944 72.5513 91.8944 72.5513 91.8944 72.5386L91.8688 72.513C91.8306 72.4748 91.7923 72.4492 91.7667 72.4109C91.7157 72.3599 91.6774 72.3089 91.6263 72.2706L91.6136 72.2578C91.5625 72.2068 91.4987 72.1812 91.4349 72.1429C91.3838 72.1174 91.3456 72.0791 91.2945 72.0536C91.2435 72.0281 91.1796 72.0026 91.1286 71.9898C91.0648 71.9643 91.0137 71.9388 90.9499 71.9132C90.8989 71.9005 90.8606 71.9005 90.8095 71.8877C90.7329 71.8749 90.6564 71.8494 90.5798 71.8494C90.5287 71.8494 90.4904 71.8494 90.4394 71.8494C90.3628 71.8494 90.2862 71.8367 90.2096 71.8494C90.1586 71.8494 90.1075 71.8622 90.0692 71.8749C89.9927 71.8877 89.9289 71.9005 89.8523 71.9132C89.814 71.926 89.7757 71.9515 89.7246 71.9643C89.6481 71.9898 89.5715 72.0153 89.4949 72.0536C89.4566 72.0664 89.4311 72.0919 89.3928 72.1174C89.3162 72.1685 89.2396 72.2068 89.1758 72.2706C89.1758 72.2706 89.1631 72.2706 89.1631 72.2833C89.112 72.3216 89.0737 72.3727 89.0354 72.4109C88.9971 72.4492 88.9589 72.4875 88.9206 72.5258L88.895 72.5513C88.895 72.5513 88.895 72.5513 88.895 72.5641L80.9691 82.0845C75.2513 88.9504 75.6469 97.6157 81.9647 104.163C82.3348 104.558 82.8453 104.75 83.3431 104.75C83.8281 104.75 84.3003 104.571 84.6704 104.214C85.4362 103.473 85.449 102.274 84.7215 101.508C79.7694 96.365 79.4631 89.8565 83.9047 84.522Z"  />
<path d="M47.8999 87.5978C47.8871 87.534 47.8871 87.4702 47.8744 87.4064C47.8616 87.3425 47.8361 87.2787 47.8233 87.2277C47.8105 87.1639 47.785 87.1128 47.7723 87.0618C47.7467 86.998 47.7084 86.9469 47.6829 86.8831C47.6574 86.8321 47.6319 86.781 47.5936 86.73C47.5553 86.6789 47.517 86.6279 47.4659 86.5768C47.4277 86.5385 47.4021 86.4875 47.3638 86.4492C47.3128 86.3982 47.2617 86.3599 47.2107 86.3216C47.1724 86.2833 47.1213 86.245 47.083 86.2195C47.032 86.1812 46.9682 86.1557 46.9044 86.1174C46.8533 86.0919 46.815 86.0664 46.764 86.0408C46.7002 86.0153 46.6363 85.9898 46.5598 85.977C46.5087 85.9643 46.4577 85.9387 46.4066 85.926C46.33 85.9132 46.2534 85.9005 46.1769 85.9005C46.1258 85.9005 46.0875 85.8877 46.0365 85.8877H46.0237H46.0109C45.9982 85.8877 45.9854 85.8877 45.9727 85.8877L40.7143 85.9898C35.5835 86.0791 31.8184 89.5248 31.3589 94.553L30.7718 100.96C30.7718 101.023 30.7718 101.074 30.7718 101.138C30.7718 101.202 30.759 101.253 30.7718 101.317V101.33C30.7973 101.559 30.8611 101.764 30.9505 101.968C30.9632 101.993 30.9632 102.006 30.976 102.031C31.0781 102.223 31.2057 102.376 31.3461 102.529C31.3717 102.555 31.3844 102.567 31.4099 102.593C31.5631 102.721 31.729 102.835 31.9205 102.912C31.946 102.925 31.9843 102.938 32.0098 102.95C32.163 103.001 32.3161 103.052 32.4948 103.065C32.5459 103.065 32.6097 103.078 32.6607 103.078C32.7118 103.078 32.7756 103.078 32.8266 103.065L39.2337 102.478C44.2624 102.019 47.6957 98.2667 47.7978 93.1237L47.8999 87.8658C47.8999 87.853 47.8999 87.8403 47.8999 87.8275C47.8999 87.8147 47.8999 87.802 47.8999 87.7892C47.9127 87.6999 47.8999 87.6488 47.8999 87.5978ZM38.9147 98.6241L34.7922 98.9942L35.1623 94.8721C35.4431 91.7964 37.5873 89.8566 40.7653 89.7928L44.0454 89.729L43.9816 93.0088C43.9306 96.1993 41.9906 98.3433 38.9147 98.6241Z"  />
<path d="M67.1468 94.3614C67.134 94.3486 67.1213 94.3486 67.1213 94.3359C67.1085 94.3231 67.1085 94.3104 67.0957 94.3104C67.0064 94.2338 66.9043 94.1955 66.8022 94.1317C66.7001 94.0551 66.598 93.9785 66.4831 93.9275C66.3682 93.8764 66.2534 93.8764 66.1385 93.8509C66.0109 93.8254 65.896 93.7871 65.7684 93.7871C65.6535 93.7871 65.5386 93.8254 65.4238 93.8509C65.2961 93.8764 65.1685 93.8764 65.0536 93.9275C64.9388 93.9785 64.8494 94.0551 64.7346 94.1317C64.6324 94.1955 64.5303 94.2338 64.441 94.3231C64.4282 94.3359 64.4282 94.3486 64.4155 94.3486C64.4027 94.3614 64.3899 94.3614 64.3899 94.3742L57.3319 101.687C51.0142 108.234 50.6313 116.912 56.3364 123.765L64.2623 133.285C64.2623 133.285 64.2623 133.285 64.2623 133.298L64.2878 133.323C64.3134 133.349 64.3389 133.362 64.3517 133.387C64.4027 133.451 64.4665 133.515 64.5303 133.566C64.5431 133.579 64.5431 133.579 64.5559 133.579C64.709 133.706 64.8877 133.796 65.0664 133.872C65.1047 133.885 65.143 133.898 65.1813 133.911C65.3599 133.962 65.5514 134.013 65.7428 134.013C65.9343 134.013 66.1257 133.974 66.3044 133.911C66.3427 133.898 66.381 133.885 66.432 133.872C66.6107 133.808 66.7894 133.706 66.9426 133.579C66.9553 133.566 66.9681 133.566 66.9681 133.566C67.0319 133.515 67.083 133.451 67.1468 133.387C67.1723 133.362 67.1978 133.349 67.2106 133.323L67.2361 133.298C67.2361 133.298 67.2361 133.298 67.2361 133.285L75.162 123.765C80.8799 116.899 80.4842 108.234 74.1665 101.687L67.1468 94.3614ZM72.252 121.302L65.7684 129.087L59.2847 121.302C54.8431 115.967 55.1494 109.459 60.0888 104.328L65.7684 98.4452L71.448 104.328C76.3873 109.459 76.6936 115.967 72.252 121.302Z"  />
<path d="M61.6586 91.6814C61.6841 91.6559 61.7096 91.6432 61.7224 91.6176C61.7224 91.6176 61.7224 91.6049 61.7351 91.6049C61.7862 91.5411 61.8372 91.4645 61.8755 91.3879C61.901 91.3496 61.9266 91.3241 61.9393 91.2858C61.9776 91.222 62.0032 91.1454 62.0287 91.0816C62.0414 91.0306 62.067 90.9923 62.0797 90.9412C62.0925 90.8774 62.1053 90.8136 62.118 90.7498C62.1308 90.686 62.1435 90.635 62.1563 90.5712C62.1563 90.5073 62.1563 90.4435 62.1563 90.3925C62.1563 90.3414 62.1563 90.2649 62.1563 90.2138C62.1563 90.15 62.1308 90.099 62.118 90.0352C62.1053 89.9713 62.1053 89.9075 62.0797 89.8437C62.067 89.7927 62.0414 89.7544 62.0287 89.7033C62.0032 89.6268 61.9776 89.563 61.9393 89.4991C61.9266 89.4609 61.8883 89.4353 61.8755 89.3971C61.8245 89.3205 61.7862 89.2439 61.7351 89.1801C61.7351 89.1801 61.7351 89.1673 61.7224 89.1673C61.6841 89.1291 61.6458 89.0908 61.6075 89.0525C61.5692 89.0014 61.5309 88.9631 61.4799 88.9121L51.9331 80.9614C45.0665 75.2441 36.4003 75.6397 29.8529 81.9569C29.0871 82.6971 29.0743 83.8967 29.8018 84.6624C30.5421 85.4281 31.7546 85.4409 32.5076 84.7134C37.6384 79.7746 44.1348 79.4555 49.4825 83.9094L57.268 90.3925L49.4825 96.8755C48.6657 97.5519 48.5636 98.7643 49.24 99.5683C49.6229 100.028 50.159 100.257 50.7078 100.257C51.1417 100.257 51.5757 100.117 51.9331 99.8108L61.4799 91.8601C61.5437 91.7963 61.5947 91.7325 61.6586 91.6814Z"  />
<path d="M31.5505 39.2556C32.01 44.2838 35.7624 47.7167 40.9059 47.8188L46.1643 47.9209C46.1771 47.9209 46.1898 47.9209 46.2026 47.9209C46.2154 47.9209 46.2281 47.9209 46.2409 47.9209C46.2919 47.9209 46.3302 47.9082 46.3813 47.9082C46.4579 47.8954 46.5344 47.8954 46.611 47.8826C46.6621 47.8699 46.7131 47.8571 46.7642 47.8316C46.828 47.8061 46.9046 47.7933 46.9684 47.7678C47.0194 47.7423 47.0705 47.7167 47.1215 47.6912C47.1854 47.6657 47.2364 47.6274 47.2875 47.5891C47.3385 47.5508 47.3768 47.5125 47.4279 47.4743C47.4789 47.436 47.53 47.3977 47.5683 47.3466C47.6065 47.3083 47.6448 47.2573 47.6831 47.219C47.7214 47.168 47.7725 47.1169 47.798 47.0659C47.8363 47.0148 47.8618 46.9638 47.8873 46.9127C47.9129 46.8617 47.9511 46.7979 47.9767 46.7468C48.0022 46.6958 48.015 46.632 48.0277 46.5682C48.0405 46.5044 48.066 46.4533 48.0788 46.3895C48.0915 46.3257 48.0915 46.2619 48.1043 46.1981C48.1043 46.147 48.1171 46.096 48.1171 46.0322C48.1171 46.0194 48.1171 46.0066 48.1171 45.9939C48.1171 45.9811 48.1171 45.9683 48.1171 45.9556L48.015 40.6977C47.9256 35.5674 44.4796 31.8026 39.4509 31.3432L33.1076 30.7689C33.0821 30.7689 33.0566 30.7689 33.0438 30.7562C32.9545 30.7434 32.8651 30.7562 32.7758 30.7562C32.7503 30.7562 32.7247 30.7562 32.6865 30.7562C31.9334 30.82 31.308 31.3177 31.0655 32.0196C31.0528 32.0451 31.04 32.0706 31.04 32.0962C31.0273 32.16 31.0145 32.211 31.0017 32.2748C30.989 32.3386 30.9762 32.4024 30.9634 32.479V32.4918C30.9634 32.5556 30.9634 32.6066 30.9634 32.6704C30.9634 32.7343 30.9507 32.7853 30.9634 32.8491L31.5505 39.2556ZM44.186 40.7743L44.2498 44.0541L40.9697 43.9903C37.7407 43.9264 35.6475 42.0377 35.3667 38.911L34.9966 34.7889L39.1191 35.159C42.1822 35.4525 44.135 37.5965 44.186 40.7743Z"  />
<path d="M39.6295 67.1402C39.6423 67.1275 39.6423 67.1147 39.655 67.1147C39.6678 67.102 39.6805 67.102 39.6805 67.0892C39.7699 66.9999 39.8082 66.8978 39.872 66.7957C39.9358 66.6936 40.0251 66.5915 40.0762 66.4766C40.1272 66.3618 40.1272 66.2341 40.1528 66.1193C40.1783 66.0044 40.2166 65.8896 40.2166 65.762C40.2166 65.6343 40.1783 65.5195 40.1528 65.4046C40.1272 65.277 40.1272 65.1621 40.0762 65.0473C40.0251 64.9324 39.9486 64.8303 39.872 64.7282C39.8082 64.6261 39.7699 64.524 39.6805 64.4347C39.6678 64.422 39.655 64.422 39.655 64.4092C39.6423 64.3964 39.6423 64.3837 39.6295 64.3837L32.3162 57.3263C25.7687 51.0092 17.1026 50.6263 10.236 56.3309L0.689209 64.2816C0.638156 64.3199 0.599867 64.3709 0.561577 64.4092C0.523288 64.4475 0.484999 64.4858 0.446709 64.524C0.446709 64.524 0.446709 64.5368 0.433946 64.5368C0.382894 64.6006 0.331841 64.6772 0.293552 64.7538C0.268026 64.792 0.242499 64.8176 0.229736 64.8559C0.191447 64.9197 0.165921 64.9962 0.140394 65.06C0.127631 65.1111 0.102105 65.1494 0.0893418 65.2004C0.0765787 65.2642 0.0638156 65.328 0.0510525 65.3919C0.0255262 65.4684 0.0127631 65.5195 0 65.5833C0 65.6471 0 65.7109 0 65.762C0 65.813 0 65.8896 0 65.9406C0 66.0044 0.0255262 66.0555 0.0382894 66.1193C0.0510525 66.1831 0.0510525 66.2469 0.0765787 66.3107C0.0893418 66.3618 0.114868 66.4 0.127631 66.4511C0.153157 66.5277 0.178684 66.5915 0.216973 66.6553C0.229736 66.6936 0.268026 66.7191 0.280789 66.7574C0.331841 66.834 0.370131 66.9105 0.421183 66.9743C0.421183 66.9743 0.421183 66.9871 0.433946 66.9871C0.459472 67.0126 0.484999 67.0254 0.497762 67.0509C0.548814 67.1147 0.61263 67.1785 0.676445 67.2296L10.2233 75.1802C13.4523 77.873 17.0898 79.213 20.7145 79.213C24.786 79.213 28.8447 77.5284 32.3035 74.1848L39.6295 67.1402ZM12.6865 72.245L4.90104 65.762L12.6865 59.2789C18.0215 54.8378 24.5307 55.144 29.6615 60.0829L35.5453 65.762L29.6615 71.441C24.5307 76.3799 18.0215 76.6861 12.6865 72.245Z"  />
<path d="M50.0824 49.465L43.5988 57.2497L37.1151 49.465C36.4386 48.6482 35.2261 48.5461 34.4221 49.2225C33.6052 49.8989 33.5031 51.1113 34.1796 51.9153L42.1055 61.4356C42.1055 61.4356 42.1055 61.4356 42.1055 61.4484L42.131 61.4739C42.1565 61.4995 42.1821 61.5122 42.1948 61.5377C42.2459 61.6016 42.3097 61.6654 42.3735 61.7164C42.3863 61.7292 42.3863 61.7292 42.399 61.7292C42.5522 61.8568 42.7309 61.9461 42.9095 62.0227C42.9478 62.0355 42.9861 62.0482 43.0372 62.061C43.2159 62.112 43.4073 62.1631 43.5988 62.1631C43.7902 62.1631 43.9816 62.1248 44.1603 62.061C44.1986 62.0482 44.2369 62.0355 44.288 62.0227C44.4667 61.9589 44.6453 61.8568 44.7985 61.7292C44.8113 61.7164 44.824 61.7164 44.824 61.7164C44.8878 61.6654 44.9389 61.6016 45.0027 61.5377C45.0282 61.5122 45.0538 61.4995 45.0665 61.4739L45.092 61.4484C45.092 61.4484 45.092 61.4484 45.092 61.4356L53.0179 51.9153C58.7358 45.0494 58.3402 36.384 52.0224 29.8372C51.2822 29.0715 50.0824 29.0587 49.3166 29.7861C48.5508 30.5263 48.5381 31.7259 49.2656 32.4916C54.2177 37.6347 54.5368 44.1305 50.0824 49.465Z"  />
<path d="M86.1 46.3897C86.1127 46.4535 86.1128 46.5173 86.1255 46.5811C86.1383 46.6449 86.1638 46.7087 86.1766 46.7725C86.1893 46.8363 86.2149 46.8874 86.2276 46.9384C86.2531 47.0022 86.2914 47.0533 86.317 47.1043C86.3425 47.1554 86.368 47.2064 86.4063 47.2575C86.4446 47.3085 86.4829 47.3596 86.5212 47.4106C86.5595 47.4489 86.5977 47.4999 86.636 47.5382C86.6871 47.5893 86.7381 47.6276 86.7892 47.6658C86.8275 47.7041 86.8785 47.7424 86.9168 47.7679C86.9679 47.8062 87.0317 47.8318 87.0955 47.87C87.1466 47.8956 87.1848 47.9211 87.2359 47.9466C87.2997 47.9721 87.3763 47.9977 87.4401 48.0104C87.4912 48.0232 87.5422 48.0487 87.5933 48.0615C87.6698 48.0742 87.7464 48.087 87.823 48.087C87.8741 48.087 87.9123 48.0998 87.9634 48.0998C87.9762 48.0998 87.9889 48.0998 88.0017 48.0998C88.0145 48.0998 88.0272 48.0998 88.04 48.0998L93.2984 47.9977C98.4292 47.9083 102.194 44.4626 102.654 39.4344L103.241 33.0279C103.241 32.9641 103.241 32.9131 103.241 32.8493C103.241 32.7855 103.254 32.7344 103.241 32.6706V32.6578C103.228 32.5813 103.215 32.5175 103.203 32.4537C103.19 32.4026 103.177 32.3388 103.164 32.2878C103.152 32.2495 103.139 32.2112 103.113 32.1729C102.858 31.4965 102.245 31.0116 101.505 30.9478C101.441 30.9478 101.377 30.9478 101.314 30.9478C101.263 30.9478 101.212 30.9478 101.148 30.9478H101.135C101.122 30.9478 101.097 30.9478 101.084 30.9478L94.7406 31.522C89.712 31.9815 86.2787 35.7335 86.1766 40.8765L86.0745 46.1344C86.0745 46.1472 86.0745 46.1599 86.0745 46.1727C86.0745 46.1855 86.0745 46.1982 86.0745 46.211C86.0745 46.2876 86.0872 46.3386 86.1 46.3897ZM95.0852 35.3634L99.2077 34.9933L98.8376 39.1154C98.5568 42.191 96.4126 44.1308 93.2346 44.1946L89.9545 44.2584L90.0183 40.9786C90.0693 37.7881 92.0093 35.6441 95.0852 35.3634Z"  />
<path d="M66.8532 39.6257C66.866 39.6385 66.8788 39.6512 66.8915 39.6512L66.9043 39.664C66.9171 39.6768 66.9298 39.6768 66.9426 39.6895C67.1213 39.8427 67.3127 39.9703 67.5169 40.0596C67.5297 40.0596 67.5425 40.0724 67.5552 40.0724C67.7722 40.1489 68.0019 40.2 68.2317 40.2C68.4614 40.2 68.6911 40.1489 68.9081 40.0724C68.9209 40.0724 68.9336 40.0596 68.9464 40.0596C69.1506 39.9703 69.3421 39.8427 69.5207 39.6895C69.5335 39.6768 69.5463 39.6768 69.559 39.664L69.5718 39.6512C69.5845 39.6385 69.5973 39.6257 69.6101 39.6257L76.6681 32.3131C82.9858 25.7663 83.3687 17.101 77.6636 10.235L69.7377 0.714667C69.7377 0.714667 69.7377 0.714667 69.7377 0.701905L69.7122 0.676381C69.6739 0.638095 69.6356 0.599809 69.5973 0.561524C69.559 0.510476 69.508 0.47219 69.4697 0.421143L69.4569 0.408381C69.3931 0.357333 69.3293 0.319048 69.2655 0.280762C69.2272 0.255238 69.1889 0.216952 69.1378 0.20419C69.0868 0.178667 69.023 0.153143 68.9592 0.127619C68.9081 0.102095 68.8443 0.0765714 68.7932 0.0638095C68.7422 0.0510476 68.7039 0.0510476 68.6528 0.0382857C68.5507 0.0255238 68.4869 0.0127619 68.4103 0C68.3593 0 68.3082 0 68.2572 0C68.1806 0 68.1168 0 68.0402 0C67.9764 0 67.9253 0.0255238 67.8615 0.0382857C67.7977 0.0510476 67.7339 0.0638095 67.6701 0.0765714C67.619 0.0893333 67.568 0.114857 67.5169 0.140381C67.4531 0.165905 67.3893 0.191429 67.3255 0.216952C67.2872 0.242476 67.2361 0.268 67.1978 0.293524C67.134 0.33181 67.0702 0.370095 67.0064 0.421143L66.9936 0.433905C66.9426 0.47219 66.9043 0.523238 66.866 0.574286C66.8277 0.612571 66.7894 0.638095 66.7511 0.689143L66.7256 0.714667C66.7256 0.714667 66.7256 0.714667 66.7256 0.727428L58.7997 10.2478C53.0818 17.1137 53.4775 25.779 59.7952 32.3259L66.8532 39.6257ZM61.748 12.6853L68.2317 4.90057L74.7153 12.6853C79.1569 18.0198 78.8506 24.5284 73.9112 29.6587L68.2317 35.5419L62.5521 29.6587C57.6127 24.5284 57.3064 18.0198 61.748 12.6853Z"  />
<path d="M72.3923 42.2676C72.354 42.3059 72.3157 42.3442 72.2775 42.3825C72.2775 42.3825 72.2774 42.3953 72.2647 42.3953C72.2136 42.4591 72.1626 42.5356 72.1243 42.6122C72.0988 42.6505 72.0732 42.676 72.0605 42.7143C72.0222 42.7781 71.9967 42.8547 71.9711 42.9185C71.9584 42.9696 71.9328 43.0078 71.9201 43.0589C71.9073 43.1227 71.8946 43.1865 71.8818 43.2503C71.869 43.3141 71.8563 43.3652 71.8435 43.429C71.8435 43.4928 71.8435 43.5566 71.8435 43.6076C71.8435 43.6587 71.8435 43.7353 71.8435 43.7863C71.8435 43.8501 71.869 43.9012 71.8818 43.965C71.8946 44.0288 71.8946 44.0926 71.9201 44.1564C71.9328 44.2075 71.9584 44.2457 71.9711 44.2968C71.9967 44.3734 72.0222 44.4372 72.0605 44.501C72.0732 44.5393 72.1115 44.5648 72.1243 44.6031C72.1753 44.6797 72.2136 44.7562 72.2647 44.82C72.2647 44.82 72.2647 44.8328 72.2775 44.8328C72.303 44.8583 72.3285 44.8711 72.3413 44.8966C72.3923 44.9604 72.4561 45.0242 72.5199 45.0753L82.0668 53.0259C85.2958 55.7187 88.9333 57.0587 92.558 57.0587C96.6295 57.0587 100.688 55.3741 104.147 52.0305C104.913 51.2903 104.926 50.0907 104.198 49.325C103.458 48.5593 102.245 48.5465 101.492 49.2739C96.3615 54.2128 89.865 54.5318 84.5173 50.0779L76.7318 43.5949L84.5173 37.1118C85.3341 36.4355 85.4362 35.2231 84.7598 34.4191C84.0833 33.6023 82.8708 33.5002 82.0668 34.1766L72.5199 42.1273C72.4689 42.1656 72.4306 42.2166 72.3923 42.2676Z"  />
</g>
</svg>

`,
}

export default logo
