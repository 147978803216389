import { LogoSVGImport } from "../index"

const logo: LogoSVGImport = {
    id: "logo-10",
    svg: `
	<svg width="134" height="134" viewBox="0 0 134 134" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M56.6399 25.2617L67.0745 35.6963L84.966 17.8048C88.2416 14.5293 93.614 14.5293 96.9019 17.8048C100.177 21.0804 100.177 26.4528 96.9019 29.7407L73.0425 53.6002C69.7669 56.8757 64.3945 56.8757 61.1066 53.6002L44.704 37.1976C41.4284 33.922 41.4284 28.5496 44.704 25.2617C47.9919 21.9861 53.3519 21.9861 56.6399 25.2617Z"  />
<path d="M67.0001 17.1222C71.7283 17.1222 75.5612 13.2893 75.5612 8.56111C75.5612 3.83294 71.7283 0 67.0001 0C62.2719 0 58.439 3.83294 58.439 8.56111C58.439 13.2893 62.2719 17.1222 67.0001 17.1222Z"  />
<path d="M77.3603 108.739L66.9256 98.3039L49.0217 116.195C45.7462 119.471 40.3738 119.471 37.0858 116.195C33.8103 112.92 33.8103 107.547 37.0858 104.259L60.9577 80.4C64.2332 77.1245 69.6056 77.1245 72.8936 80.4L89.2962 96.8026C92.5717 100.078 92.5717 105.451 89.2962 108.739C86.0082 112.014 80.6482 112.014 77.3603 108.739Z"  />
<path d="M67.0001 134C71.7283 134 75.5612 130.167 75.5612 125.439C75.5612 120.711 71.7283 116.878 67.0001 116.878C62.2719 116.878 58.439 120.711 58.439 125.439C58.439 130.167 62.2719 134 67.0001 134Z"  />
<path d="M108.739 56.6397L98.3039 67.0743L116.195 84.9658C119.471 88.2413 119.471 93.6137 116.195 96.9017C112.92 100.177 107.547 100.177 104.259 96.9017L80.4 73.0422C77.1245 69.7667 77.1245 64.3943 80.4 61.1063L96.8026 44.7037C100.078 41.4282 105.451 41.4282 108.739 44.7037C112.014 47.9917 112.014 53.3517 108.739 56.6397Z"  />
<path d="M125.439 75.5612C130.167 75.5612 134 71.7283 134 67.0001C134 62.2719 130.167 58.439 125.439 58.439C120.711 58.439 116.878 62.2719 116.878 67.0001C116.878 71.7283 120.711 75.5612 125.439 75.5612Z"  />
<path d="M25.2614 77.3603L35.696 66.9256L17.8046 49.0218C14.529 45.7462 14.529 40.3738 17.8046 37.0858C21.0801 33.7979 26.4525 33.8103 29.7405 37.0858L53.5999 60.9577C56.8755 64.2332 56.8755 69.6056 53.5999 72.8936L37.1973 89.2962C33.9218 92.5718 28.5494 92.5718 25.2614 89.2962C21.9859 86.0082 21.9859 80.6482 25.2614 77.3603Z"  />
<path d="M8.56111 75.5612C13.2893 75.5612 17.1222 71.7283 17.1222 67.0001C17.1222 62.2719 13.2893 58.439 8.56111 58.439C3.83294 58.439 0 62.2719 0 67.0001C0 71.7283 3.83294 75.5612 8.56111 75.5612Z"  />
</g>
</svg>

`,
}

export default logo
