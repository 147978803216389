import { LogoSVGImport } from "../index"

const logo: LogoSVGImport = {
    id: "logo-5",
    svg: `
	<svg width="151" height="135" viewBox="0 0 151 135" xmlns="http://www.w3.org/2000/svg">
<path d="M37.3524 89.0743L23.6203 112.818L33.4952 129.894L50.2361 100.953L66.9771 71.9976H33.4952H0L9.87491 89.0743H37.3524Z" />
<path d="M37.7103 45.3177H10.2328L0.35791 62.3944H33.8398H67.335L50.594 33.4394L33.8398 4.49756L23.9649 21.5743L37.7103 45.3177Z" />
<path d="M75.858 23.7434L62.1126 0H42.3628L59.1038 28.9418L75.858 57.8836L92.5989 28.9418L109.34 0H89.59L75.858 23.7434Z" />
<path d="M113.648 45.9261L127.393 22.1827L117.518 5.10596L100.764 34.061L84.0229 63.0028H117.518H151L141.125 45.9261H113.648Z" />
<path d="M113.29 89.6826H140.767L150.655 72.6191H117.16H83.665L100.419 101.561L117.16 130.503L127.035 113.426L113.29 89.6826Z" />
<path d="M75.1421 111.256L88.8874 135H108.637L91.8963 106.058L75.1421 77.1162L58.4011 106.058L41.6602 135H61.41L75.1421 111.256Z" />
</svg>

`,
}

export default logo
